@import '@ngg/components/dist/style.css';
@import '@ngg/ui/dist/style.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @keyframes banner {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(var(--banner-translate, -33.333%));
    }
  }

  @keyframes cart-animation {
    0%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.3);
    }
    75% {
      transform: scale(0.9);
    }
  }
  .animation-pause {
    animation-play-state: paused;
  }
}

@layer base {
  :root {
    /* font-size: 14px; */
    --header-height: 6rem;
    --brand-banner-height: 5rem;
  }

  /* Remove arrows on number input firefox */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

[id^='zoid-imbox-launcher'],
[id^='zoid-imbox-launcher'] .imbox-frame iframe {
  z-index: 10 !important;
}
